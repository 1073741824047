import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import remark from "remark"
import recommended from "remark-preset-lint-recommended"
import remarkHtml from "remark-html"
import Image from "../components/image"
import Footer from "../components/footer/footer.component"
import Header from "../components/header/header.component"
import "../styles/styles.css"

import SEO from "../components/seo"
import therms from '../../static/images/terms.svg'

const Terms = () => {
  const data = useStaticQuery(graphql`
    query Termsquery {
      markdownRemark(fileAbsolutePath: { regex: "/terms.md/" }) {
        frontmatter {
          about
          termsimage
          title
        }
      }
    }
  `)

  const toHTML = value =>
    remark().use(recommended).use(remarkHtml).processSync(value).toString()

  function createMarkup(data) {
    const dataHtml = toHTML(data)

    return { __html: dataHtml }
  }

  return (
    <div>
      <SEO
        title="Terms and Conditions"
        url="https://tokorame.co.id/terms-and-conditions"
      />
      <Header />
      <div className="container-policy">
        <div className="policy">
          <h1>{data.markdownRemark.frontmatter.title}</h1>
          {/* <Image
            name={data.markdownRemark.frontmatter.termsimage.replace(
              "../src/images/",
              ""
            )}
          /> */}
          <img src={therms} className="rounded mx-auto d-block"></img>
        </div>
          <div
            className="policy-content"
            dangerouslySetInnerHTML={createMarkup(
              data.markdownRemark.frontmatter.about
            )}
          ></div>
      </div>
      <Footer />
    </div>
  )
}

export default Terms
